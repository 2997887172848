@use "../common/colors";
@use "../common/typography";
@use "../common/lozenge";

.kds-ticket-list-item-header-widget {
  padding: 10px 16px;
  border-bottom: 1px solid colors.$blue-gray-100;
  display: grid;

  > div:not(:last-of-type) {
    margin-bottom: 6px;
  }

  &.delivery,
  &.pickup {
    grid-template-areas:
      'collection collection collection'
      'time-order-source time-order-source time-order-source'
      'customer-name customer-name docket-number'
      'employee-name employee-name employee-name'
      'created-time created-time created-time';
  }

  &.dineIn {
    grid-template-areas:
      'collection collection collection'
      'time-order-source time-order-source time-order-source'
      'customer-name customer-name table-name'
      'employee-name employee-name employee-name'
      'created-time created-time created-time';
  }

  > .collection {
    grid-area: collection;
    display: flex;
    align-items: center;
    @include typography.body-3-500;
    line-height: 21px;
    color: colors.$blue-gray-900;

    .image {
      display: flex;
      margin-right: 6px;

      > img {
        width: 26px;
        border-radius: 4px;
      }
    }

    .icon {
      margin-right: 6px;
    }
  }

  > .time-order-source {
    grid-area: time-order-source;
    display: flex;

    .asap {
      @include lozenge.secondary-rose(medium);
      margin-right: 6px;
    }

    .date-time {
      @include lozenge.secondary-green(medium);
      margin-right: 6px;
    }

    .order-source {
      @include lozenge.secondary-gray(medium);
    }
  }

  > .customer-name {
    grid-area: customer-name;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 4px;
    }
  }

  > .docket-number {
    grid-area: docket-number;
    justify-self: end;
  }

  > .table-name {
    grid-area: table-name;
    justify-self: end;
  }

  > .employee-name {
    grid-area: employee-name;

    .icon {
      margin-right: 4px;
    }
  }

  > .created-time {
    grid-area: created-time;
  }
}
