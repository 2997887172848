@use "../common/colors";
@use "../common/typography";

.orders-page {
  > .header {
    > .wrapper {
      > .actions {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .kds-ticket-count-widget {
          @include typography.body-1-400;
          color: colors.$blue-gray-700;
        }
      }
    }
  }

  > .body {
    .no-orders {
      @include typography.body-1-400;
      display: grid;
      place-content: center;
    }
  }
}
