@use "colors";
@use "typography";

@mixin container {
  background-color: colors.$white;
  border-radius: 12px;
  color: colors.$blue-gray-700;
  box-shadow: 0px 2px 24px 0px #0000000f;
  min-width: 320px;
  height: calc(100vh - 112px);
  @include typography.caption-2-400;
  display: grid;
  grid-template-rows: auto 1fr auto;
}
