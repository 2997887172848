@use "elements";
@use "colors";
@use "typography";

@mixin base {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 11.5px 32px;
  padding-left: 102px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.15);
  z-index: 998;

  > .text {
    @include typography.caption-1-400;
    display: inherit;
    align-items: inherit;
    color: colors.$blue-gray-900;

    > .icon {
      @include elements.icon(24px);
      margin-right: 8px;
    }

    > .highlight {
      @include typography.body-3-500;
      display: inherit;
      align-items: inherit;
      margin-right: 8px;
    }
  }

  > .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
      &:first-child {
        margin-left: 0;
      }

      margin-left: 12px;
    }

    button {
      @include typography.caption-2-400;
      border-radius: 4px;
      padding: 7px 16px;

      &.refresh-button {
        background-color: colors.$teal-600;
        border: 1px solid colors.$teal-600;
        color: colors.$white;
      }

      &.later-button {
        border: 1px solid colors.$yellow-600;
        color: colors.$rose-800;
        background-color: transparent;
      }
    }

    > .continue-transaction-button,
    .void-transaction-button {
      padding: 9.5px 24px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    > .continue-transaction-button {
      background-color: colors.$teal-600;
      border: solid 1px colors.$teal-600;
      color: colors.$white;
    }

    > .void-transaction-button {
      background-color: transparent;
      border: solid 1px colors.$rose-500;
      color: colors.$rose-800;

      &.transaction-failed {
        background-color: colors.$rose-800;
        border: none;
        color: colors.$white;
      }
    }
  }
}
