@use "colors";
@use "constants";
@use "typography";

@mixin _button {
  width: 100%;
  padding: 14px 32px;
  border: solid 1px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.loading-widget {
  display: grid;
  gap: 16px;
  padding: 24px 24px 32px;
  border-radius: constants.$border-radius;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
  text-align: center;
  width: 573px;
  box-sizing: border-box;

  > .title {
    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  > .message {
    color: colors.$blue-gray-700;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .action {
    display: grid;
    grid-auto-flow: column;
    margin-top: 10px;
    gap: 16px;
    grid-auto-columns: 1fr;
    .cancel-button {
      @include _button;
      background: none;
      border-color: colors.$blue-gray-700;
      color: colors.$blue-gray-700;
    }
  }
}
