@use "../common/colors";

.key-value-pair {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'label value';
  align-items: center;

  .label {
    grid-area: label;
    display: flex;

    .info {
      margin-left: 4px;
      position: relative;

      .info-text {
        visibility: hidden;
        position: absolute;

        z-index: 2;
        width: 160px;
        padding: 16px;

        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 8px 12px 0px rgba(9, 30, 66, 0.15), 0px 0px 1px 0px rgba(9, 30, 66, 0.31);

        color: colors.$blue-gray-900;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: 0.03px;

        top: -45px;
        left: 120%;
      }

      .info-text::before {
        content: '';
        position: absolute;
        transform: rotate(45deg);
        background-color: #fff;
        padding: 5px;
        z-index: 1;

        top: 47%;
        left: -2%;
      }
    }

    .info:hover {
      .info-text {
        visibility: visible;
      }
    }
  }
  .value {
    grid-area: value;
  }
}
