@use "../common/typography";
@use "../common/colors";
@use "../common/viewport";

.kds-time-settings-edit-widget {
  margin-bottom: 32px;

  .title {
    color: colors.$blue-gray-900;
    @include typography.body-2-500;
    margin-bottom: 12px;
  }

  form {
    .form-items {
      .form-item {
        display: grid;
        grid-template-areas:
          'label dropdown'
          'help-text dropdown';
        column-gap: 24px;
        align-items: center;
        padding: 24px;
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;

        @include viewport.mobile {
          grid-template-areas:
            'label'
            'help-text'
            'dropdown';
        }

        label {
          @include typography.body-2-500;
          margin-bottom: 8px;
          color: colors.$blue-gray-900;
          grid-area: label;

          &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: colors.$yellow-600;
            margin-right: 8px;
          }
        }

        .meta {
          .help {
            @include typography.caption-1-400;
            color: colors.$blue-gray-400;
            grid-area: help-text;
          }
        }

        .form-input {
          grid-area: dropdown;

          .input-box {
            @include typography.caption-1-400;
            padding: 12px 14px;
            border: 1px solid colors.$blue-gray-100;
            color: colors.$blue-gray-900;
            border-radius: 8px;
          }

          .options {
            width: 100%;
            padding: 20px 0;
            grid-gap: 0;

            .option {
              padding: 12px 14px;
              position: relative;

              .icon {
                display: none;
              }

              &.selected {
                background-color: colors.$teal-50;

                &::after {
                  content: '';
                  position: absolute;
                  width: 14px;
                  height: 10px;
                  right: 14px;
                  top: 50%;
                  transform: translateY(-50%);
                  background-image: url('../images/icon-check-teal.svg');
                }
              }
            }
          }
        }
      }
    }
  }
}
