@use "../common/colors";
@use "../common/typography";
@use "../common/kds-ticket-list-item-widget";

.active-kds-ticket-list-widget {
  > .active-kds-ticket-list-item-widget {
    @include kds-ticket-list-item-widget.container();

    > .kds-ticket-list-item-footer-widget {
      border-top: 1px solid colors.$blue-gray-100;
      padding: 8px;

      &.rejected,
      &.cancelled {
        border-top: 0;
        background-color: colors.$rose-50;
      }

      .warning-notification {
        display: flex;
        justify-content: center;
        padding: 4px 0 12px 0;

        .icon {
          margin-right: 8px;
        }

        .label {
          color: colors.$rose-800;
          @include typography.caption-1-400;
        }
      }

      button {
        width: 100%;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include typography.caption-2-400;
        font-weight: 500;

        &.complete-button {
          background-color: transparent;
          border: 1px solid colors.$teal-300;
          padding: 7px;

          .label {
            display: contents;
          }

          &.completion-in-progress {
            background-color: colors.$teal-500;
          }
        }

        &.remove-button {
          color: colors.$white;
          background-color: colors.$rose-800;
          padding: 10px;

          &:hover {
            background-color: colors.$rose-900;
          }
        }
      }
    }
  }
}
