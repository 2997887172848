@use "../common/viewport";
@use "../common/typography";
@use "../common/constants";

header {
  grid-area: header;
  display: grid;

  overflow: hidden;
  align-items: center;
  background-color: #454545;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
  padding: 0;
  height: constants.$header-height;
  z-index: 1;

  .logo {
    margin-left: constants.$horizontal-gap;

    img {
      display: block;
      width: 100%;
    }

    &.icon {
      font-size: 20px;
      color: #ffffff;
      justify-self: start;
      align-self: center;
      cursor: pointer;
    }
  }
}
