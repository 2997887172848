.kds-layout {
  height: 100vh;
  width: 100vw;

  &:has(.refreshing-menu.show) {
    > main {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  > main {
    margin-left: 70px;
    height: inherit;
  }

  .cannot-access {
    margin-top: 0;
    padding: 20px;
    margin-left: 84px;
  }

  > .sidebar {
    z-index: 2002;
  }
}
