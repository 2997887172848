@use "../common/colors";
@use "../common/typography";

.kds-ticket-update-banner-widget {
  background-color: colors.$blue-800;
  color: colors.$white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 12px 16px;
  margin: 10px;
  box-shadow: 0px 6px 10px 0px #091e421a, 0px 0px 3px 0px #091e421f;
  @include typography.button-2;

  .info-icon {
    width: 20px;
    height: 20px;
  }

  .label {
    margin: 0 12px;
  }

  .close-icon {
    cursor: pointer;
  }
}
