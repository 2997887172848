@use "../common/typography";
@use "../common/colors";

.store-info-widget {
  display: flex;
  align-items: center;

  .image {
    margin-right: 12px;

    img {
      width: 48px;
      border-radius: 8px;
      border: 1px solid colors.$light-gray-200;
    }
  }

  .store-name {
    @include typography.h1;
    color: colors.$blue-gray-700;
    margin-bottom: 0;
  }
}
