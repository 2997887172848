@use "../common/colors";
@use "../common/typography";

.collection-type-filter-widget {
  display: flex;
  gap: 8px;

  > .filter-button {
    @include typography.body-2-500;
    background-color: colors.$white;
    color: colors.$blue-gray-700;
    letter-spacing: 0.05em;
    padding: 8px 12px;
    border-radius: 6px;

    &.selected {
      background-color: colors.$teal-800;
      color: colors.$white;
    }
  }
}
