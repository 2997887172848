@use "../common/colors";
@use "../common/typography";

.form-multi-select-checkbox {
  .clear-selection-button {
    @include typography.body-3-500;
    text-decoration: underline;
    color: colors.$blue-800;
    padding: 9.5px 16px;
  }

  .option {
    cursor: pointer;
    display: flex;
    padding: 4px;
    margin: 8px 0;
    @include typography.body-2-500;
    font-weight: 400;

    &.checked {
      background-color: colors.$teal-50;
      border-radius: 8px;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .checkbox {
    width: 24px;
    background-image: url('../images/icon-checkbox-square.svg');
    background-repeat: no-repeat;

    &.checked {
      background-image: url('../images/icon-checkbox-square-checked.svg');
    }
  }

  label {
    padding-left: 15px;
    color: colors.$blue-gray-700;
  }
}
