@use "typography";
@use "buttons";
@use "colors";

.page {
  height: inherit;
  background-color: colors.$light-gray-100;
  display: grid;
  grid-template-rows: 88px calc(100vh - 88px);
  place-items: center;

  > .header {
    width: 100%;
    margin: 0;

    .wrapper {
      padding: 24px;
      display: block;
    }
  }

  > .body {
    display: grid;
    height: 100%;
    width: 100%;
  }
}
