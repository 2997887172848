@use "../common/typography";
@use "../common/scrollbar";
@use "../common/colors";

.sidebar {
  height: 100%;
  width: 72px;
  position: fixed;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
  gap: 40px;
  background-color: colors.$white;
  border-right: 1px solid colors.$blue-gray-100;
  overflow-y: auto;
  @include scrollbar.hide();

  .restoplus-logo {
    .icon {
      width: 30px;
      margin-top: 5px;
    }
  }

  .link {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    place-items: center;
    padding: 0;

    .label {
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.025em;
      text-align: center;
      color: colors.$blue-gray-500;

      &.selected {
        color: colors.$teal-600;
      }
    }
  }

  .main-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .action {
    display: grid;
    place-items: center;
    margin-bottom: 16px;
    cursor: pointer;

    img {
      width: 32px;
      display: block;
    }

    .label {
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.025em;
      text-align: center;
      color: colors.$blue-gray-500;
      margin-top: 10px;
    }
  }
}
