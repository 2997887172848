@use "../common/cta";
@use "../common/typography";
@use "../common/colors";

.login-widget {
  height: 100vh;

  .login {
    height: 100%;
    width: 100%;
    background: radial-gradient(50% 50% at 50% 50%, rgb(46, 47, 80) 0%, rgb(28, 29, 62) 100%);
    display: grid;
    place-items: center;
  }

  .form {
    width: 50%;
    max-width: 320px;

    &-header {
      margin-bottom: 75px;
      text-align: center;

      &-description {
        @include typography.body-2-500;
        letter-spacing: 0.05em;
        color: colors.$white;
        margin-top: 10px;
        font-family: 'Chivo', sans-serif;
      }
    }

    > form.default {
      .form-input {
        position: relative;

        input {
          @include typography.caption-1-400;
          letter-spacing: 0.025em;
          outline: none;
          background-color: colors.$white;
          color: colors.$blue-gray-900;
          border: 1px solid colors.$blue-gray-100;
          height: 40px;
          padding: 12px 14px;
          border-radius: 8px;

          &::placeholder {
            color: colors.$blue-gray-400;
          }
        }

        &.invalid {
          input {
            border: 1px solid colors.$rose-500;
          }
        }

        .show-password {
          position: absolute;
          right: 15px;
          top: 12px;
          color: #{colors.$white}90;
          font-size: 12px;
          cursor: pointer;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
      }

      .login-button {
        @include cta.primary-cta;
        @include typography.button-1;
        letter-spacing: 0.05em;
        width: 100%;
        margin-top: 24px;
        padding: 14px 32px;
      }
    }
  }
}
