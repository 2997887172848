// common
@use "common/actions";
@use "common/alert";
@use "common/aspect-ratio";
@use "common/banner";
@use "common/buttons";
@use "common/card";
@use "common/checkbox";
@use "common/colors";
@use "common/constants";
@use "common/cta";
@use "common/drop-down-input";
@use "common/duration-input";
@use "common/elements";
@use "common/error-boundary";
@use "common/gap";
@use "common/ghost";
@use "common/input";
@use "common/loading-widget";
@use "common/lozenge";
@use "common/menu-sidebar";
@use "common/modal";
@use "common/page";
@use "common/pill";
@use "common/popout";
@use "common/scrollbar";
@use "common/spinner";
@use "common/sweetalert";
@use "common/switch";
@use "common/tabbed-navigation";
@use "common/text-input";
@use "common/kds-ticket-list-item-widget";
@use "common/toast";
@use "common/typography";
@use "common/viewport";

// forms
@use "forms/form-color-picker";
@use "forms/form-input";
@use "forms/form-multi-select-checkbox";
@use "forms/form-multi-select-pill";
@use "forms/form-select";
@use "forms/form-single-select-pill";
@use "forms/form";

// layout
@use "layout/kds-layout";

// pages
@use "pages/orders-page";
@use "pages/settings-page";

// widgets
@use "widgets/active-kds-ticket-list-widget";
@use "widgets/alert-widget";
@use "widgets/collection-type-filter-widget";
@use "widgets/completed-kds-ticket-list-widget";
@use "widgets/error-widget";
@use "widgets/header";
@use "widgets/key-value-pair";
@use "widgets/link";
@use "widgets/login-widget";
@use "widgets/offline-widget";
@use "widgets/restart-instructions-widget";
@use "widgets/search-widget";
@use "widgets/sidebar";
@use "widgets/kds-ticket-list-item-body-widget";
@use "widgets/kds-ticket-list-item-header-widget";
@use "widgets/kds-ticket-list-widget";
@use "widgets/kds-ticket-update-banner-widget";
@use "widgets/troubleshooting-options-widget";
@use "widgets/type-select-widget";
@use "widgets/store-info-widget";
@use "widgets/kds-time-settings-edit-widget";
@use "widgets/service-worker-update-banner-widget";
@use 'widgets/kds-prep-time-change-confirm-widget';

@font-face {
  font-family: 'Sailec-Medium';
  src: url('../fonts/Sailec-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Sailec-Regular';
  src: url('../fonts/Sailec-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Sailec-Bold';
  src: url('../fonts/Sailec-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'kredit';
  src: url('../fonts/kredit.ttf') format('truetype');
}

@font-face {
  font-family: 'larabie';
  src: url('../fonts/larabie.ttf') format('truetype');
}

@font-face {
  font-family: 'Chivo';
  src: url('../fonts/Chivo.ttf') format('truetype');
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: colors.$white;
  font-size: 15px;
  line-height: 1.6;
  overscroll-behavior-y: none;
  @include typography.p;

  // Hide refresh menu banner if the user is in lock screen
  #root {
    &:has(.kds-layout) {
      .refresh-menu-banner {
        display: none;
      }
    }
  }
}

/*Temporary css declaration to hide emulator warning until internal bug is fixed and `disableWarnings` flag can be passed in without typescript warnings*/
.firebase-emulator-warning {
  display: none;
}

// Google autocomplete
.pac-container {
  max-height: 135px;
  overflow: auto;
}
