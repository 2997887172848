@use "../common/colors";
@use "../common/typography";
@use "../common/constants";

.settings-page {
  background-color: colors.$white;

  > .header {
    > .wrapper {
      padding: 24px 32px;
      > .actions {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }

  > .body {
    display: block;
    padding: 0 32px;

    > .refresh-button {
      @include typography.body-2-500;
      color: colors.$teal-600;
      background-color: colors.$white;
      border: 1px solid colors.$teal-600;
      padding: 14px 32px;
      border-radius: constants.$border-radius-small;
      width: 148px;

      > .label {
        display: flex;

        img {
          width: 16.8px;
          height: 19.2px;
          margin-right: 8px;
        }
      }
    }
  }
}
