:root {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
}

@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-right: constant(safe-area-inset-right);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    --safe-area-inset-left: constant(safe-area-inset-left);
  }
}

@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-right: env(safe-area-inset-right);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
  }
}

h1 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 10px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

h2 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 22px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

h3 {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

h4 {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

h5 {
  letter-spacing: 0;
  color: #1c1d3e;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

p, pre {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  font-size: 15px;
}

.alert {
  border-radius: 10px;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "icon message";
  padding: 10px 15px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.alert.has-title {
  grid-template-areas: "icon title"
                       ". message";
}

.alert .icon {
  flex-direction: column;
  grid-area: icon;
  justify-self: center;
  font-size: 20px;
  display: flex;
}

.alert .icon i {
  margin-right: 10px;
  font-size: 25px;
}

.alert .title {
  letter-spacing: 0;
  grid-area: title;
  align-self: center;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.alert .message {
  grid-area: message;
  align-self: center;
  font-size: .9rem;
  font-weight: 300;
}

.alert.success {
  background-color: #e3fcef;
}

.alert.success .icon, .alert.success .title, .alert.success .message {
  color: #064;
}

.alert.info {
  color: #0071b3;
  background-color: #e6edfa;
}

.alert.info .icon, .alert.info .title, .alert.info .message {
  color: #0071b3;
}

.alert.error {
  color: #bf2600;
  background-color: #ffebe6;
}

.alert.error .icon, .alert.error .title, .alert.error .message {
  color: #bf2600;
}

.alert.warning {
  color: #ff8b00;
  background-color: #fffae6;
}

.alert.warning .icon, .alert.warning .title, .alert.warning .message {
  color: #ff8b00;
}

.ar-4-2 {
  aspect-ratio: 4 / 2;
  object-fit: cover;
}

.ar-4-2-5 {
  aspect-ratio: 4 / 2.5;
  object-fit: cover;
}

.ar-8-3 {
  aspect-ratio: 8 / 3;
  object-fit: cover;
}

.ar-4-3 {
  aspect-ratio: 4 / 3;
  object-fit: cover;
}

.ar-1-1 {
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.ar-4-5 {
  aspect-ratio: 4 / 5;
  object-fit: cover;
}

.ar-4-6 {
  aspect-ratio: 4 / 6;
  object-fit: cover;
}

button {
  text-align: center;
  cursor: pointer;
  border: 0;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

button .icon {
  margin-right: 7px;
  font-size: 20px;
}

button.primary {
  color: #fff;
  background-color: #3f9c86;
}

button.success {
  color: #fff;
  background-color: #454545;
}

button.failure {
  color: #fff;
  background-color: red;
}

button.disabled {
  color: #fff;
  opacity: .4;
  cursor: not-allowed;
  background-color: #4cbba1;
  border-color: #4cbba1;
}

.cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.cta:focus {
  border: none;
  outline: none;
}

.secondary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #4cbba1;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
  color: #4cbba1 !important;
}

.tertiary-cta {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  color: #4c4e68;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 5px;
  margin: 0;
  padding: 15px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  transition: all .2s ease-in-out;
}

.tertiary-cta:focus {
  border: none;
  outline: none;
}

.link-cta {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  background-color: #0000;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.duration-input {
  border: 1px solid #c6c7d1;
  grid-auto-flow: column;
  gap: 10px;
  padding: 0 8px;
  display: grid;
}

.duration-input .hours-input, .duration-input .minutes-input {
  grid-auto-flow: column;
  display: grid;
}

.duration-input .hours-input input[type="text"], .duration-input .minutes-input input[type="text"] {
  width: 22px;
  border: none;
  outline: none;
}

.duration-input .hours-input .label, .duration-input .minutes-input .label {
  font-size: 17px;
}

.duration-input.invalid {
  border: 1px solid #f43f5e;
}

.container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 22px;
  padding-left: 35px;
  font-size: 22px;
  display: block;
  position: relative;
}

.container.checkbox > input[type="checkbox"] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: absolute;
}

.container.checkbox > input[type="checkbox"]:checked ~ .checkmark {
  background-color: #4cbba1;
  border: 2px solid #4cbba1;
}

.container.checkbox > input[type="checkbox"]:checked ~ .checkmark:after {
  display: block;
}

.container.checkbox > .checkmark {
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 2px solid #dee0e6;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.container.checkbox > .checkmark:after {
  content: "";
  width: 5px;
  height: 10px;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  display: none;
  position: absolute;
  top: 1px;
  left: 5px;
  transform: rotate(45deg);
}

.container.radio > input[type="radio"] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  position: absolute;
}

.container.radio > input[type="radio"]:checked ~ .checkmark {
  background-color: #4cbba1;
  border: 2px solid #4cbba1;
}

.container.radio > input[type="radio"]:checked ~ .checkmark:after {
  display: block;
}

.container.radio > .checkmark {
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 2px solid #dee0e6;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.container.radio > .checkmark:after {
  content: "";
  width: 4px;
  height: 4px;
  background: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 3px;
  left: 3px;
}

.loading-widget {
  text-align: center;
  width: 573px;
  box-sizing: border-box;
  border-radius: 10px;
  gap: 16px;
  padding: 24px 24px 32px;
  display: grid;
  box-shadow: 0 0 3px #687a9b40;
}

.loading-widget > .title {
  color: #1c1d3e;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.loading-widget > .message {
  color: #4c4e68;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.loading-widget .action {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  gap: 16px;
  margin-top: 10px;
  display: grid;
}

.loading-widget .action .cancel-button {
  width: 100%;
  color: #4c4e68;
  background: none;
  border: 1px solid #4c4e68;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

#modal-container > .ReactModalPortal > .ReactModal__Overlay {
  z-index: 2003;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #0c0d3473 !important;
}

#modal-container > .ReactModalPortal > .ReactModal__Overlay > .ReactModal__Content {
  border-radius: 20px !important;
  padding: 0 !important;
}

.modal .close {
  width: 27px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.page {
  height: inherit;
  background-color: #f4f4f6;
  grid-template-rows: 88px calc(100vh - 88px);
  place-items: center;
  display: grid;
}

.page > .header {
  width: 100%;
  margin: 0;
}

.page > .header .wrapper {
  padding: 24px;
  display: block;
}

.page > .body {
  height: 100%;
  width: 100%;
  display: grid;
}

.popout.arrow-right-bottom:before, .popout.arrow-right-center:before, .popout.arrow-right-top:before, .popout.arrow-down-right:before, .popout.arrow-down-center:before, .popout.arrow-down-left:before, .popout.arrow-left-bottom:before, .popout.arrow-left-center:before, .popout.arrow-left-top:before, .popout.arrow-up-right:before, .popout.arrow-up-center:before, .popout.arrow-up-left:before {
  content: "";
  position: absolute;
}

.popout.arrow-up-right:before, .popout.arrow-up-center:before, .popout.arrow-up-left:before {
  border-bottom: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  top: -12px;
}

.popout.arrow-left-bottom:before, .popout.arrow-left-center:before, .popout.arrow-left-top:before {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-right: 12px solid #fff;
  left: -12px;
}

.popout.arrow-down-right:before, .popout.arrow-down-center:before, .popout.arrow-down-left:before {
  border-top: 12px solid #fff;
  border-left: 12px solid #0000;
  border-right: 12px solid #0000;
  bottom: -12px;
}

.popout.arrow-right-bottom:before, .popout.arrow-right-center:before, .popout.arrow-right-top:before {
  border-top: 12px solid #0000;
  border-bottom: 12px solid #0000;
  border-left: 12px solid #fff;
  right: -12px;
}

.popout.arrow-right-center:before, .popout.arrow-left-center:before {
  top: 50%;
  transform: translateY(-50%);
}

.popout.arrow-up-center:before, .popout.arrow-down-center:before {
  left: 50%;
  transform: translateX(-50%);
}

.popout.arrow-right-top:before, .popout.arrow-left-top:before {
  top: 12px;
}

.popout.arrow-right-bottom:before, .popout.arrow-left-bottom:before {
  bottom: 12px;
}

.popout.arrow-up-left:before, .popout.arrow-down-left:before {
  left: 12px;
}

.popout.arrow-up-right:before, .popout.arrow-down-right:before {
  right: 12px;
}

.popout {
  z-index: 99;
  background-color: #fff;
  border-radius: 10px;
  padding: 16px;
  position: absolute;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.popout.arrow-down-left, .popout.arrow-down-center, .popout.arrow-down-right {
  margin-top: -12px;
}

.popout.arrow-left-top, .popout.arrow-left-center, .popout.arrow-left-bottom {
  margin-left: 12px;
}

.popout.arrow-up-left, .popout.arrow-up-center, .popout.arrow-up-right {
  margin-top: 12px;
}

.popout.arrow-right-top, .popout.arrow-right-center, .popout.arrow-right-bottom {
  margin-left: -12px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-flow: column;
  grid-column: 1 / -1;
  grid-template-rows: min-content min-content;
  place-content: center;
  display: grid;
}

.spinner-container .spinner {
  color: #4cbba1;
  text-align: center;
  font-size: 30px;
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-container > p {
  color: #1c1d3e;
  margin: 0;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.swal2-container {
  background-color: #0c0d3473 !important;
}

.swal2-container .swal2-modal {
  width: 340px !important;
  border-radius: 20px !important;
  padding: 15px 0 25px !important;
}

.swal2-container .swal2-modal .swal2-header {
  padding: 10px 30px;
  position: relative;
}

.swal2-container .swal2-modal .swal2-content {
  white-space: pre-wrap;
  margin: 0;
  padding: 0 30px;
  font-size: 14px;
}

.swal2-container .swal2-modal .swal2-title {
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #1c1d3e !important;
  margin: 0 !important;
}

.swal2-container .swal2-modal .swal2-html-container {
  color: #1c1d3e;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.swal2-container .swal2-modal .swal2-html-container .highlight {
  color: #3f9c86;
}

.swal2-container .swal2-modal .swal2-footer {
  border-top: none;
  margin: 0;
  padding: 24px 25px 0;
  font-size: 14px;
}

.swal2-container .swal2-modal .swal2-actions {
  flex-direction: column;
  gap: 15px;
  margin: 0;
  padding: 24px 24px 0;
  display: flex;
}

.swal2-container .swal2-modal .swal2-actions button {
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  padding: 14px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  text-align: center !important;
}

.swal2-container .swal2-modal .swal2-actions .confirm-button {
  background: #3f9c86;
  border-radius: 4px;
}

.swal2-container .swal2-modal .swal2-actions .cancel-button {
  color: #4c4e68;
  background: #fff !important;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-content, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-content {
  color: #4c4e68;
  font-size: 16px;
  line-height: 24px;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-actions button, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-actions button {
  text-transform: none;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-actions .confirm-button, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-actions .confirm-button {
  background: #9f1239;
  padding: 14px;
  font-size: 16px;
  line-height: 24px;
}

.swal2-container .swal2-modal.troubleshoot-logout .swal2-actions .cancel-button, .swal2-container .swal2-modal.troubleshoot-clear-cache .swal2-actions .cancel-button {
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  transition: all .2s ease-in-out;
}

.swal2-container .swal2-modal.has-icon {
  width: 573px !important;
}

.swal2-container .swal2-modal.has-icon .swal2-error.swal2-icon-show {
  height: 64px;
  width: 64px;
  border-radius: unset;
  background: url("icon-error.d5c53330.svg") center no-repeat;
  border: none;
}

.swal2-container .swal2-modal.has-icon .swal2-error.swal2-icon-show .swal2-x-mark {
  display: none;
}

.swal2-container .swal2-modal.has-icon .swal2-success.swal2-icon-show {
  height: 64px;
  width: 64px;
  border-radius: unset;
  background: url("icon-success.85cdf8e7.gif") center / 64px no-repeat;
  border: none;
}

.swal2-container .swal2-modal.has-icon .swal2-success.swal2-icon-show > * {
  display: none;
}

.swal2-container .swal2-icon-warning.swal2-show {
  width: 357px !important;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-header {
  padding: 32px 24px 16px;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-warning.swal2-icon-show {
  height: 64px;
  width: 64px;
  background: url("icon-warning.464bf84a.svg") center no-repeat;
  border: none;
  margin: 0;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-warning.swal2-icon-show .swal2-icon-content {
  padding-top: 16px;
  display: none;
}

.swal2-container .swal2-icon-warning.swal2-show .swal2-actions {
  padding-bottom: 32px !important;
}

.form-switch {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
}

.form-switch i {
  width: 46px;
  height: 26px;
  vertical-align: text-bottom;
  background-color: #e6e6e6;
  border-radius: 23px;
  margin-right: 4px;
  transition: all .3s linear;
  display: inline-block;
  position: relative;
}

.form-switch i:before {
  content: "";
  width: 42px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .25s linear;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0)scale3d(1, 1, 1);
}

.form-switch i:after {
  content: "";
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  transition: all .2s ease-in-out;
  position: absolute;
  left: 0;
  transform: translate3d(2px, 2px, 0);
  box-shadow: 0 2px 2px #0000003d;
}

.form-switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i {
  background-color: #4bd763;
}

.form-switch input:checked + i:before {
  transform: translate3d(18px, 2px, 0)scale3d(0, 0, 0);
}

.form-switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}

@media (max-width: 767px) {
  .form-item.switch {
    grid-template-columns: auto max-content;
    grid-template-areas: "a b"
                         "c d";
    display: grid;
  }

  .form-item.switch label {
    grid-area: a;
    align-self: center;
  }

  .form-item.switch .form-input {
    grid-area: b;
    justify-self: end;
  }

  .form-item.switch .meta {
    margin-top: -3px;
    grid-area: c !important;
  }
}

form.default .form-item.switch {
  grid-template-columns: auto max-content;
  grid-template-areas: "a b"
                       "c d";
  display: grid;
}

form.default .form-item.switch label {
  grid-area: a;
  align-self: center;
}

form.default .form-item.switch .form-input {
  grid-area: b;
  justify-self: end;
}

form.default .form-item.switch .meta {
  margin-top: -3px;
  grid-area: c !important;
}

#toasts-container {
  grid-gap: 15px;
  z-index: 1000;
  display: grid;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

#toasts-container .wrapper {
  text-align: center;
}

.toast {
  border-radius: 4px;
  grid-template-columns: min-content max-content;
  grid-template-areas: "icon message";
  align-items: center;
  padding: 10px 15px;
  display: inline-grid;
  box-shadow: 0 0 3px #687a9b40;
}

.toast .icon {
  grid-area: icon;
  margin-right: 10px;
  font-size: 30px;
}

.toast .message {
  max-width: 585px;
  grid-area: message;
  margin: 0;
  font-size: .85rem;
  font-weight: 400;
}

.toast button.close {
  box-shadow: none;
  opacity: .5;
  color: #000;
  background-color: #0000;
  border: 0;
  margin-left: 10px;
  padding: 0;
  font-size: 1.4rem;
}

.toast.success {
  color: #064;
  background-color: #e3fcef;
}

.toast.info {
  color: #1e40af;
  background-color: #dbeafe;
  border: 1px solid #3b82f6;
}

.toast.error {
  color: #9f1239;
  background-color: #ffe4e6;
  border: 1px solid #f43f5e;
}

.toast.warning {
  color: #92400e;
  background-color: #fef3c7;
  border: 1px solid #f59e0b;
}

.toast.loading {
  color: #505f79;
  background-color: #fff;
}

.toast.loading .icon {
  grid-area: icon;
  display: none;
}

.form-color-picker .form-input {
  min-width: 251px;
  max-width: 283px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.form-color-picker .form-input .header {
  min-height: 80px;
  color: #1c1d3e;
  border-bottom: 1px solid #f1f1f5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 12px 18px;
  display: flex;
}

.form-color-picker .form-input .header > .icon img {
  width: 40px;
  margin-bottom: 18px;
  padding-top: 12px;
}

.form-color-picker .form-input .header > .label {
  letter-spacing: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.form-color-picker .form-input .header > .sub-text {
  margin-top: 15px;
}

.form-color-picker .form-input .picker {
  padding: 15px 18px;
}

.form-color-picker .form-input .picker .color-palette {
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  gap: 10px;
  margin-bottom: 10px;
  display: grid;
}

.form-color-picker .form-input .picker .color-palette .color {
  aspect-ratio: 1 / 1;
  height: 35px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.form-color-picker .form-input .picker .color-palette .color.selected {
  background-image: url("icon-check-black.6727bc5a.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid #000 !important;
}

form label {
  letter-spacing: 0;
  color: #1c1d3e;
  margin-bottom: 15px;
  margin-left: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  resize: none;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form input[type="text"]::placeholder, form input[type="number"]::placeholder, form input[type="password"]::placeholder, form input[type="tel"]::placeholder, form textarea::placeholder {
  color: #4c4e68;
}

form input[type="file"]::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 5px;
}

form input[type="file"] {
  font-family: Poppins, sans-serif;
}

form .preview {
  margin-bottom: 15px;
}

form .preview img {
  max-width: 100px;
  border: 3px solid #f1f1f5;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

form .form-input.has-icon {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  resize: none;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

form .form-input.has-icon::placeholder {
  color: #4c4e68;
}

form .form-input.has-icon .icon {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 12px;
  display: flex;
}

form .form-input.has-icon img {
  width: 100%;
  align-self: center;
}

form .form-input.has-icon input {
  border: 0;
  margin: 0;
}

form .form-items {
  grid-row-gap: 18px;
  display: grid;
}

form .form-item {
  position: relative;
}

form .form-item .form-input > input {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

form .form-item .input-icon {
  opacity: .4;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  display: grid;
  position: absolute;
  top: 0;
  right: 10px;
}

form .form-buttons {
  margin-top: 20px;
}

form .select {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  resize: none;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form .select::placeholder {
  color: #4c4e68;
}

form .meta {
  margin-top: 5px;
  margin-left: 5px;
}

form .error {
  color: #f43f5e;
  font-size: 12px;
}

form .help {
  color: #6c6c6c;
  font-size: 12px;
}

form select {
  box-sizing: none;
  padding: none;
  width: 100%;
  background: none;
  border: none;
  margin: 0;
}

form .checkbox {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  form.responsive {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item > label {
    text-align: right;
    align-self: center;
    margin-right: 15px;
  }

  form.responsive .form-items {
    grid-area: 1 / 1 / auto / -1;
    row-gap: 25px;
  }

  form.responsive .form-items > .form-item {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item label {
    grid-area: 1 / 1 / auto / 2;
  }

  form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / auto / 3;
  }

  form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 2 / auto / 3;
  }

  form.responsive .form-input {
    margin-top: 0;
  }

  form.responsive .form-buttons {
    grid-area: 2 / 2 / auto / 3;
    margin-top: 30px;
    padding-left: 4px;
  }

  form.horizontal .form-items {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    display: grid;
  }
}

textarea, input, .input-box {
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

textarea.invalid, input.invalid, .input-box.invalid {
  border-color: #f43f5e !important;
}

textarea > span, input > span, .input-box > span {
  color: #4c4e68c4;
}

input:disabled, .form-input.disabled {
  background: #f9f9f9 !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 18px !important;
}

.form-multi-select-checkbox .clear-selection-button {
  color: #1e40af;
  padding: 9.5px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
}

.form-multi-select-checkbox .option {
  cursor: pointer;
  margin: 8px 0;
  padding: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

.form-multi-select-checkbox .option.checked {
  background-color: #ecf9f6;
  border-radius: 8px;
}

.form-multi-select-checkbox .option.disabled {
  opacity: .5;
}

.form-multi-select-checkbox .checkbox {
  width: 24px;
  background-image: url("icon-checkbox-square.1749cfa2.svg");
  background-repeat: no-repeat;
}

.form-multi-select-checkbox .checkbox.checked {
  background-image: url("icon-checkbox-square-checked.8ccfd7b7.svg");
}

.form-multi-select-checkbox label {
  color: #4c4e68;
  padding-left: 15px;
}

.form-multi-select-pill {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form-multi-select-pill .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.form-multi-select-pill .help-text {
  color: #f43f5e;
  margin-bottom: 1rem;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
}

.form-multi-select-pill .error {
  margin-left: 10px;
  margin-right: auto;
}

.form-multi-select-pill .options.small {
  flex-wrap: wrap;
  justify-self: end;
  display: flex;
}

.form-multi-select-pill .options.small .option {
  cursor: pointer;
  color: #1c1d3e;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  padding: 5.5px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.form-multi-select-pill .options.small .option.selected {
  color: #fff;
  background: #1c1d3e;
}

form label {
  z-index: 1;
  margin-left: 4px;
  font-size: .8rem;
}

form input[type="text"], form input[type="number"], form input[type="password"], form input[type="tel"], form textarea {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form input[type="file"]::-webkit-file-upload-button {
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 5px;
}

form input[type="file"] {
  font-family: Poppins, sans-serif;
}

form .preview {
  margin-bottom: 15px;
}

form .preview img {
  max-width: 100px;
  border: 3px solid #f1f1f5;
  border-radius: 20px;
  margin: 0;
  padding: 0;
}

form .form-input.has-icon {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

form .form-input.has-icon .icon {
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 12px;
  display: flex;
}

form .form-input.has-icon img {
  width: 100%;
  align-self: center;
}

form .form-input.has-icon input {
  border: 0;
  margin: 0;
}

form .form-input .has-prepend {
  display: flex;
}

form .form-input .has-prepend .prepend-text {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  width: auto;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-right: none;
  border-radius: 10px 0 0 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form .form-input .has-prepend input {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

form .form-items {
  grid-row-gap: 18px;
  display: grid;
}

form .form-buttons {
  margin-top: 20px;
}

form .select {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  margin: 0;
  padding: 10px 14px;
  font-weight: 400;
  transition: all .3s;
}

form .meta {
  margin-top: 5px;
  margin-left: 5px;
}

form .error {
  color: #f43f5e;
  font-size: 12px;
}

form .help {
  color: #6c6c6c;
  font-size: 12px;
}

form select {
  box-sizing: none;
  padding: none;
  width: 100%;
  background: none;
  border: none;
  margin: 0;
}

form .checkbox {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  form.responsive {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item > label {
    text-align: right;
    align-self: center;
    margin-right: 15px;
  }

  form.responsive .form-items {
    grid-area: 1 / 1 / auto / -1;
    row-gap: 25px;
  }

  form.responsive .form-items > .form-item {
    grid-template-columns: 180px 4fr 1fr;
    display: grid;
  }

  form.responsive .form-items > .form-item label {
    grid-area: 1 / 1 / auto / 2;
  }

  form.responsive .form-items > .form-item .form-input {
    grid-area: 1 / 2 / auto / 3;
  }

  form.responsive .form-items > .form-item .meta {
    grid-area: 2 / 2 / auto / 3;
  }

  form.responsive .form-input {
    margin-top: 0;
  }

  form.responsive .form-buttons {
    grid-area: 2 / 2 / auto / 3;
    margin-top: 30px;
    padding-left: 4px;
  }

  form.horizontal .form-items {
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    display: grid;
  }
}

textarea.invalid, input.invalid, .input-box.invalid {
  border-color: #fe5b5b !important;
}

input:disabled, .form-input.disabled {
  background: #f9f9f9 !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  font-weight: 400;
  padding: 8px 18px !important;
}

.form-select .input-box {
  box-sizing: border-box;
  color: #4c4e68;
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 10px 14px;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s;
  display: flex;
}

.form-select .input-box:after {
  content: "";
  height: 24px;
  width: 25px;
  background: url("icon-chevron-down-grey-alt.043fbf9c.svg") no-repeat;
}

.form-select.disabled .input-box {
  color: #696974;
  cursor: auto;
  background-color: #f5f5f5;
  border-color: #e2e2ea;
}

.form-select .options {
  grid-gap: 18px;
  cursor: pointer;
  max-height: 400px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 20px 25px 20px 18px;
  font-weight: 400;
  display: grid;
  overflow-y: scroll;
}

@media (max-width: 767px) {
  .form-select .options {
    max-width: 80vw;
    max-height: 80vh;
    overflow: scroll;
  }

  .form-select .overlay {
    z-index: 998;
    background: #000000b3;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    inset: 0;
  }
}

@media (min-width: 768px) {
  .form-select .options-wrapper {
    position: relative;
  }

  .form-select .options {
    z-index: 999;
    margin-top: 10px;
    position: absolute;
    top: 100%;
  }
}

.form-select .icon {
  color: #777;
  margin-right: 12px;
  font-size: 30px;
}

.form-select .selected .icon {
  color: #065f46;
}

.form-select .option {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.form-single-select-pill {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.form-single-select-pill .label {
  color: #1c1d3e;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.form-single-select-pill .help-text {
  color: #f43f5e;
  margin-bottom: 1rem;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
}

.form-single-select-pill .error {
  margin-left: 10px;
  margin-right: auto;
}

.form-single-select-pill .options.small {
  flex-wrap: wrap;
  justify-self: end;
  display: flex;
}

.form-single-select-pill .options.small .option {
  cursor: pointer;
  color: #1c1d3e;
  text-align: center;
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  padding: 5.5px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  box-shadow: 0 0 2px #0000001a, 0 2px 3px #0003;
}

.form-single-select-pill .options.small .option.selected {
  color: #fff;
  background: #1c1d3e;
}

.kds-layout {
  height: 100vh;
  width: 100vw;
}

.kds-layout:has(.refreshing-menu.show) > main {
  opacity: .4;
  pointer-events: none;
}

.kds-layout > main {
  height: inherit;
  margin-left: 70px;
}

.kds-layout .cannot-access {
  margin-top: 0;
  margin-left: 84px;
  padding: 20px;
}

.kds-layout > .sidebar {
  z-index: 2002;
}

.orders-page > .header > .wrapper > .actions {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.orders-page > .header > .wrapper > .actions .kds-ticket-count-widget {
  color: #4c4e68;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.orders-page > .body .no-orders {
  place-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  display: grid;
}

.settings-page {
  background-color: #fff;
}

.settings-page > .header > .wrapper {
  padding: 24px 32px;
}

.settings-page > .header > .wrapper > .actions {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.settings-page > .body {
  padding: 0 32px;
  display: block;
}

.settings-page > .body > .refresh-button {
  color: #3f9c86;
  width: 148px;
  background-color: #fff;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  padding: 14px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.settings-page > .body > .refresh-button > .label {
  display: flex;
}

.settings-page > .body > .refresh-button > .label img {
  width: 16.8px;
  height: 19.2px;
  margin-right: 8px;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget {
  color: #4c4e68;
  min-width: 320px;
  height: calc(100vh - 112px);
  background-color: #fff;
  border-radius: 12px;
  grid-template-rows: auto 1fr auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: grid;
  box-shadow: 0 2px 24px #0000000f;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget {
  border-top: 1px solid #dee0e6;
  padding: 8px;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget.rejected, .active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget.cancelled {
  background-color: #fff1f2;
  border-top: 0;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget .warning-notification {
  justify-content: center;
  padding: 4px 0 12px;
  display: flex;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget .warning-notification .icon {
  margin-right: 8px;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget .warning-notification .label {
  color: #9f1239;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget button {
  width: 100%;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget button.complete-button {
  background-color: #0000;
  border: 1px solid #83d0be;
  padding: 7px;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget button.complete-button .label {
  display: contents;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget button.complete-button.completion-in-progress {
  background-color: #4cbba1;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget button.remove-button {
  color: #fff;
  background-color: #9f1239;
  padding: 10px;
}

.active-kds-ticket-list-widget > .active-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget button.remove-button:hover {
  background-color: #88133a;
}

.alert-widget {
  text-align: center;
  width: 50vw;
  min-width: 357px;
  max-width: 573px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  display: flex;
}

.alert-widget > .icon {
  width: 64px;
}

.alert-widget > .title {
  color: #1c1d3e;
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.alert-widget > .content {
  color: #7d7e92;
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
}

.alert-widget > .actions {
  width: 100%;
  height: min-content;
  margin-top: 24px;
}

.alert-widget > .actions button {
  color: #fff;
  width: inherit;
  background-color: #3f9c86;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
}

.alert-widget a {
  color: #3f9c86;
  text-decoration: none;
}

.alert-widget button {
  padding: 14px 32px;
}

.alert-widget.retry {
  width: 40vw;
  min-width: 573px;
}

.alert-widget.retry > .actions {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  display: grid;
}

.alert-widget.retry > .actions .cancel-button {
  color: #7d7e92;
  background: none;
  border: 1px solid #7d7e92;
}

.alert-widget.success .icon {
  width: unset;
}

.alert-widget.warning, .alert-widget.info {
  max-width: 397px;
}

.alert-widget.warning > .title, .alert-widget.info > .title {
  font-size: 18px;
}

.alert-widget.warning > .content, .alert-widget.info > .content {
  width: 70%;
}

.alert-widget.warning > .actions, .alert-widget.info > .actions {
  gap: 16px;
  display: grid;
}

.alert-widget.warning > .actions .cancel-button, .alert-widget.info > .actions .cancel-button {
  color: #7d7e92;
  background: none;
  border: 1px solid #7d7e92;
}

.alert-widget.warning > .actions .proceed-button, .alert-widget.info > .actions .proceed-button {
  color: #fff;
  background-color: #9f1239;
}

.collection-type-filter-widget {
  gap: 8px;
  display: flex;
}

.collection-type-filter-widget > .filter-button {
  color: #4c4e68;
  letter-spacing: .05em;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.collection-type-filter-widget > .filter-button.selected {
  color: #fff;
  background-color: #296a5b;
}

.completed-kds-ticket-list-widget > .completed-kds-ticket-list-item-widget {
  color: #4c4e68;
  min-width: 320px;
  height: calc(100vh - 112px);
  background-color: #fff;
  border-radius: 12px;
  grid-template-rows: auto 1fr auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  display: grid;
  overflow: hidden;
  box-shadow: 0 2px 24px #0000000f;
}

.completed-kds-ticket-list-widget > .completed-kds-ticket-list-item-widget > .kds-ticket-list-item-footer-widget {
  background-color: #f6f8fb;
  border-top: 1px solid #dee0e6;
  padding: 16.5px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.error-widget {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.error-widget .icon {
  height: 96px;
  aspect-ratio: 1 / 1;
}

.error-widget .title {
  color: #1c1d3e;
  padding-top: 30px;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

.error-widget .body {
  color: #7d7e92;
  width: 60vh;
  text-align: center;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.error-widget button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  margin: 30px 0 60px;
  padding: 14px 32px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.error-widget button:disabled {
  opacity: unset;
  background-color: #c4eae1;
  border: 1px solid #c4eae1;
}

.error-widget button.logout-button {
  background-color: #9f1239;
  border: 1px solid #9f1239;
}

.error-widget a {
  color: #1e40af;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

header {
  height: 53px;
  z-index: 1;
  background-color: #454545;
  grid-area: header;
  align-items: center;
  padding: 0;
  display: grid;
  overflow: hidden;
  box-shadow: 0 0 3px #687a9b40;
}

header .logo {
  margin-left: 20px;
}

header .logo img {
  width: 100%;
  display: block;
}

header .logo.icon {
  color: #fff;
  cursor: pointer;
  place-self: center start;
  font-size: 20px;
}

.key-value-pair {
  grid-template-columns: auto 1fr;
  grid-template-areas: "label value";
  align-items: center;
  display: grid;
}

.key-value-pair .label {
  grid-area: label;
  display: flex;
}

.key-value-pair .label .info {
  margin-left: 4px;
  position: relative;
}

.key-value-pair .label .info .info-text {
  visibility: hidden;
  z-index: 2;
  width: 160px;
  color: #1c1d3e;
  text-align: center;
  letter-spacing: .03px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  top: -45px;
  left: 120%;
  box-shadow: 0 8px 12px #091e4226, 0 0 1px #091e424f;
}

.key-value-pair .label .info .info-text:before {
  content: "";
  z-index: 1;
  background-color: #fff;
  padding: 5px;
  position: absolute;
  top: 47%;
  left: -2%;
  transform: rotate(45deg);
}

.key-value-pair .label .info:hover .info-text {
  visibility: visible;
}

.key-value-pair .value {
  grid-area: value;
}

.link {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
}

.link .icon {
  width: 32px;
}

.link .icon img {
  width: 100%;
}

.link .label {
  color: #7d7e92;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 38px;
}

.link .label.selected {
  color: #4cbba1;
}

.login-widget {
  height: 100vh;
}

.login-widget .login {
  height: 100%;
  width: 100%;
  background: radial-gradient(50% 50%, #2e2f50 0%, #1c1d3e 100%);
  place-items: center;
  display: grid;
}

.login-widget .form {
  width: 50%;
  max-width: 320px;
}

.login-widget .form-header {
  text-align: center;
  margin-bottom: 75px;
}

.login-widget .form-header-description {
  letter-spacing: .05em;
  color: #fff;
  margin-top: 10px;
  font-family: Chivo, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.login-widget .form > form.default .form-input {
  position: relative;
}

.login-widget .form > form.default .form-input input {
  letter-spacing: .025em;
  color: #1c1d3e;
  height: 40px;
  background-color: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  outline: none;
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.login-widget .form > form.default .form-input input::placeholder {
  color: #9597a7;
}

.login-widget .form > form.default .form-input.invalid input {
  border: 1px solid #f43f5e;
}

.login-widget .form > form.default .form-input .show-password {
  color: #ffffff90;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  font-size: 12px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.login-widget .form > form.default .login-button {
  letter-spacing: 0;
  color: #1c1d3e;
  text-align: center;
  cursor: pointer;
  letter-spacing: .05em;
  width: 100%;
  background-color: #3f9c86;
  border-radius: 5px;
  margin: 24px 0 0;
  padding: 14px 32px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.login-widget .form > form.default .login-button:focus {
  border: none;
  outline: none;
}

.offline-widget {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.offline-widget .icon {
  height: 96px;
  aspect-ratio: 1 / 1;
}

.offline-widget .title {
  color: #1c1d3e;
  padding-top: 30px;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

.offline-widget .body {
  color: #7d7e92;
  width: 60vh;
  text-align: center;
  padding-top: 16px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.offline-widget button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
  border-radius: 4px;
  margin: 30px 0 60px;
  padding: 14px 32px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.offline-widget button:disabled {
  opacity: unset;
  background-color: #c4eae1;
  border: 1px solid #c4eae1;
}

.offline-widget button.logout-button {
  background-color: #9f1239;
  border: 1px solid #9f1239;
}

.offline-widget a {
  color: #1e40af;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.restart-instructions-widget {
  gap: 24px;
  padding: 32px 24px;
  display: grid;
}

.restart-instructions-widget .header {
  color: #1c1d3e;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.restart-instructions-widget .instructions {
  max-width: 40vw;
  color: #4c4e68;
  gap: 20px;
  padding-left: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  display: grid;
}

.search-widget {
  margin-bottom: 20px;
  display: grid;
}

.search-widget .inputbox {
  height: 44px;
  text-indent: 30px;
  min-width: 112.59px;
  background-image: url("icon-search.efe91b61.svg");
  background-position: 12px;
  background-repeat: no-repeat;
  background-size: 18px;
  border: none;
  border-radius: 8px;
  outline: none;
  padding: 8px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  box-shadow: 0 3px 15px -5px #0000004d;
}

.search-widget .inputbox:focus {
  outline: 2px solid #4cbba1;
}

.search-widget .clear-search-btn {
  margin-top: 10px;
}

.sidebar {
  height: 100%;
  width: 72px;
  z-index: 999;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #fff;
  border-right: 1px solid #dee0e6;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 0 5px;
  display: flex;
  position: fixed;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 1px;
}

.sidebar::-webkit-scrollbar-track {
  background: none;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.sidebar .restoplus-logo .icon {
  width: 30px;
  margin-top: 5px;
}

.sidebar .link {
  grid-auto-flow: row;
  justify-content: center;
  place-items: center;
  padding: 0;
  display: grid;
}

.sidebar .link .label {
  letter-spacing: .025em;
  text-align: center;
  color: #7d7e92;
  font-size: 10px;
  line-height: 15px;
}

.sidebar .link .label.selected {
  color: #3f9c86;
}

.sidebar .main-menu {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  display: flex;
}

.sidebar .action {
  cursor: pointer;
  place-items: center;
  margin-bottom: 16px;
  display: grid;
}

.sidebar .action img {
  width: 32px;
  display: block;
}

.sidebar .action .label {
  letter-spacing: .025em;
  text-align: center;
  color: #7d7e92;
  margin-top: 10px;
  font-size: 10px;
  line-height: 15px;
}

.kds-ticket-list-item-body-widget {
  padding: 8px 16px;
  overflow: scroll;
}

.kds-ticket-list-item-body-widget .item {
  grid-template-areas: "quantity name"
                       ". item-description"
                       ". item-comments";
  justify-content: start;
  gap: 2px 4px;
  margin-bottom: 12px;
  display: grid;
}

.kds-ticket-list-item-body-widget .item .item-quantity {
  grid-area: quantity;
  padding-right: 4px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.kds-ticket-list-item-body-widget .item .item-name {
  color: #1c1d3e;
  grid-area: name;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.kds-ticket-list-item-body-widget .item .item-description {
  color: #7d7e92;
  grid-area: item-description;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.kds-ticket-list-item-body-widget .item .item-description > div {
  white-space: break-spaces;
}

.kds-ticket-list-item-body-widget .item .item-description > div:not(:last-of-type) {
  margin-bottom: 8px;
}

.kds-ticket-list-item-body-widget .item .item-comments {
  color: #7d7e92;
  grid-area: item-comments;
}

.kds-ticket-list-item-body-widget .comments {
  color: #7d7e92;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.kds-ticket-list-item-body-widget.cancelled .item *, .kds-ticket-list-item-body-widget.cancelled .comments, .kds-ticket-list-item-body-widget.rejected .item *, .kds-ticket-list-item-body-widget.rejected .comments {
  color: #adafbc;
}

.kds-ticket-list-item-body-widget.completion-in-progress {
  opacity: .25;
  text-decoration: line-through;
}

.kds-ticket-list-item-header-widget {
  border-bottom: 1px solid #dee0e6;
  padding: 10px 16px;
  display: grid;
}

.kds-ticket-list-item-header-widget > div:not(:last-of-type) {
  margin-bottom: 6px;
}

.kds-ticket-list-item-header-widget.delivery, .kds-ticket-list-item-header-widget.pickup {
  grid-template-areas: "collection collection collection"
                       "time-order-source time-order-source time-order-source"
                       "customer-name customer-name docket-number"
                       "employee-name employee-name employee-name"
                       "created-time created-time created-time";
}

.kds-ticket-list-item-header-widget.dineIn {
  grid-template-areas: "collection collection collection"
                       "time-order-source time-order-source time-order-source"
                       "customer-name customer-name table-name"
                       "employee-name employee-name employee-name"
                       "created-time created-time created-time";
}

.kds-ticket-list-item-header-widget > .collection {
  color: #1c1d3e;
  grid-area: collection;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
}

.kds-ticket-list-item-header-widget > .collection .image {
  margin-right: 6px;
  display: flex;
}

.kds-ticket-list-item-header-widget > .collection .image > img {
  width: 26px;
  border-radius: 4px;
}

.kds-ticket-list-item-header-widget > .collection .icon {
  margin-right: 6px;
}

.kds-ticket-list-item-header-widget > .time-order-source {
  grid-area: time-order-source;
  display: flex;
}

.kds-ticket-list-item-header-widget > .time-order-source .asap {
  color: #9f1239;
  border: 1px solid #fecdd3;
  border-radius: 4px;
  margin-right: 6px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.kds-ticket-list-item-header-widget > .time-order-source .date-time {
  color: #047857;
  border: 1px solid #a7f3d0;
  border-radius: 4px;
  margin-right: 6px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.kds-ticket-list-item-header-widget > .time-order-source .order-source {
  color: #4c4e68;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.kds-ticket-list-item-header-widget > .customer-name {
  grid-area: customer-name;
  align-items: center;
  display: flex;
}

.kds-ticket-list-item-header-widget > .customer-name .icon {
  margin-right: 4px;
}

.kds-ticket-list-item-header-widget > .docket-number {
  grid-area: docket-number;
  justify-self: end;
}

.kds-ticket-list-item-header-widget > .table-name {
  grid-area: table-name;
  justify-self: end;
}

.kds-ticket-list-item-header-widget > .employee-name {
  grid-area: employee-name;
}

.kds-ticket-list-item-header-widget > .employee-name .icon {
  margin-right: 4px;
}

.kds-ticket-list-item-header-widget > .created-time {
  grid-area: created-time;
}

.kds-ticket-list-widget {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-auto-flow: column;
  gap: 20px;
  padding: 0 24px 24px;
  display: grid;
  overflow: scroll;
}

.kds-ticket-list-widget::-webkit-scrollbar {
  width: 1px;
}

.kds-ticket-list-widget::-webkit-scrollbar-track {
  background: none;
}

.kds-ticket-list-widget::-webkit-scrollbar-thumb {
  background-color: #0000;
}

.kds-ticket-update-banner-widget {
  color: #fff;
  background-color: #1e40af;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 12px 16px;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  box-shadow: 0 6px 10px #091e421a, 0 0 3px #091e421f;
}

.kds-ticket-update-banner-widget .info-icon {
  width: 20px;
  height: 20px;
}

.kds-ticket-update-banner-widget .label {
  margin: 0 12px;
}

.kds-ticket-update-banner-widget .close-icon {
  cursor: pointer;
}

.troubleshooting-options-widget {
  color: #1c1d3e;
  gap: 32px;
  margin: 0 auto;
  padding: 40px 64px 32px;
  display: grid;
}

.troubleshooting-options-widget .title {
  text-align: center;
  letter-spacing: .36px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
}

.troubleshooting-options-widget .actions {
  padding: 0 24px;
}

.troubleshooting-options-widget .actions button.cancel, .troubleshooting-options-widget .actions button.back {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.troubleshooting-options-widget .header {
  gap: 12px;
  display: grid;
}

.troubleshooting-options-widget .header .title {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

.troubleshooting-options-widget .header .subtitle {
  text-align: center;
  color: #4c4e68;
  max-width: 60vw;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

.troubleshooting-options-widget .header .subtitle span {
  color: #3f9c86;
}

.troubleshooting-options-widget .troubleshooting-options {
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 24px;
  margin: 0 32px;
  display: grid;
}

.troubleshooting-options-widget .troubleshooting-options .option {
  letter-spacing: .09px;
  text-align: center;
  cursor: pointer;
  grid-gap: 24px;
  background: #fff;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 40px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  display: grid;
  box-shadow: 0 0 24px #00000014;
}

.troubleshooting-options-widget .troubleshooting-options .option:hover {
  background: linear-gradient(#f7f9fc 0%, #fff 100%);
  outline: 2px solid #4f506a;
}

.troubleshooting-options-widget .troubleshooting-options .option > .icon {
  place-self: center;
}

.troubleshooting-options-widget .troubleshooting-options .option .icon {
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.troubleshooting-options-widget .troubleshooting-options .option .icon > img {
  height: 100%;
}

.troubleshooting-options-widget .back-button {
  width: 100%;
  text-align: center;
  color: #7d7e92;
  cursor: pointer;
  color: #4c4e68;
  background-color: #0000;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  font-size: 17px;
  line-height: 23px;
  transition: all .2s ease-in-out;
}

.store-info-widget {
  align-items: center;
  display: flex;
}

.store-info-widget .image {
  margin-right: 12px;
}

.store-info-widget .image img {
  width: 48px;
  border: 1px solid #e9e9ec;
  border-radius: 8px;
}

.store-info-widget .store-name {
  letter-spacing: 0;
  color: #1c1d3e;
  color: #4c4e68;
  margin-bottom: 0;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.kds-time-settings-edit-widget {
  margin-bottom: 32px;
}

.kds-time-settings-edit-widget .title {
  color: #1c1d3e;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.kds-time-settings-edit-widget form .form-items .form-item {
  border: 1px solid #dee0e6;
  border-radius: 8px;
  grid-template-areas: "label dropdown"
                       "help-text dropdown";
  align-items: center;
  column-gap: 24px;
  padding: 24px;
  display: grid;
}

@media (max-width: 767px) {
  .kds-time-settings-edit-widget form .form-items .form-item {
    grid-template-areas: "label"
                         "help-text"
                         "dropdown";
  }
}

.kds-time-settings-edit-widget form .form-items .form-item label {
  color: #1c1d3e;
  grid-area: label;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.kds-time-settings-edit-widget form .form-items .form-item label:before {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #d97706;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.kds-time-settings-edit-widget form .form-items .form-item .meta .help {
  color: #9597a7;
  grid-area: help-text;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input {
  grid-area: dropdown;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .input-box {
  color: #1c1d3e;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options {
  width: 100%;
  grid-gap: 0;
  padding: 20px 0;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option {
  padding: 12px 14px;
  position: relative;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option .icon {
  display: none;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option.selected {
  background-color: #ecf9f6;
}

.kds-time-settings-edit-widget form .form-items .form-item .form-input .options .option.selected:after {
  content: "";
  width: 14px;
  height: 10px;
  background-image: url("icon-check-teal.88df1ce6.svg");
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

.service-worker-update-banner-widget {
  width: 100vw;
  z-index: 998;
  z-index: 2001;
  background: #fef3c7;
  justify-content: space-between;
  align-items: center;
  padding: 11.5px 32px 11.5px 102px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 24px #00000026;
}

.service-worker-update-banner-widget > .text {
  display: inherit;
  align-items: inherit;
  color: #1c1d3e;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.service-worker-update-banner-widget > .text > .icon {
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.service-worker-update-banner-widget > .text > .icon > img {
  height: 100%;
}

.service-worker-update-banner-widget > .text > .highlight {
  display: inherit;
  align-items: inherit;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.service-worker-update-banner-widget > .actions {
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-worker-update-banner-widget > .actions > * {
  margin-left: 12px;
}

.service-worker-update-banner-widget > .actions > :first-child {
  margin-left: 0;
}

.service-worker-update-banner-widget > .actions button {
  border-radius: 4px;
  padding: 7px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.service-worker-update-banner-widget > .actions button.refresh-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.service-worker-update-banner-widget > .actions button.later-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #d97706;
}

.service-worker-update-banner-widget > .actions > .continue-transaction-button, .service-worker-update-banner-widget > .actions .void-transaction-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 9.5px 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.service-worker-update-banner-widget > .actions > .continue-transaction-button {
  color: #fff;
  background-color: #3f9c86;
  border: 1px solid #3f9c86;
}

.service-worker-update-banner-widget > .actions > .void-transaction-button {
  color: #9f1239;
  background-color: #0000;
  border: 1px solid #f43f5e;
}

.service-worker-update-banner-widget > .actions > .void-transaction-button.transaction-failed {
  color: #fff;
  background-color: #9f1239;
  border: none;
}

.service-worker-update-banner-widget > .text > .highlight {
  color: #9f1239;
}

.kds-prep-time-change-confirm-widget {
  width: 357px;
  border-radius: 20px;
  padding: 32px 24px;
}

.kds-prep-time-change-confirm-widget > .title {
  color: #1c1d3e;
  text-align: center;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}

.kds-prep-time-change-confirm-widget > .message {
  color: #7d7e92;
  text-align: center;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.kds-prep-time-change-confirm-widget > .buttons .confirm-btn {
  color: #fff;
  width: 100%;
  background-color: #2563eb;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.kds-prep-time-change-confirm-widget > .buttons .go-back-btn {
  color: #4c4e68;
  width: 100%;
  background-color: #fff;
  border: 1px solid #7d7e92;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 14px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@font-face {
  font-family: Sailec-Medium;
  src: url("Sailec-Medium.7fce2372.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Sailec-Regular;
  src: url("Sailec-Regular.80b73846.ttf") format("truetype");
}

@font-face {
  font-family: Sailec-Bold;
  src: url("Sailec-Bold.b115272d.otf") format("truetype");
}

@font-face {
  font-family: kredit;
  src: url("kredit.fcba7869.ttf") format("truetype");
}

@font-face {
  font-family: larabie;
  src: url("larabie.08226e3a.ttf") format("truetype");
}

@font-face {
  font-family: Chivo;
  src: url("Chivo.d16fe4f0.ttf") format("truetype");
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overscroll-behavior-y: none;
  background-color: #fff;
  font-family: Sailec-Regular, Saira Condensed, Poppins, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
}

body #root:has(.kds-layout) .refresh-menu-banner, .firebase-emulator-warning {
  display: none;
}

.pac-container {
  max-height: 135px;
  overflow: auto;
}

/*# sourceMappingURL=index.c0f623a1.css.map */
