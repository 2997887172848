@use "typography";
@use "colors";
@use "cta";

.swal2-container {
  background-color: rgba(12, 13, 52, 0.45) !important;

  .swal2-modal {
    border-radius: 20px !important;
    width: 340px !important;
    padding: 15px 0 !important;
    padding-bottom: 25px !important;

    .swal2-header {
      position: relative;
      padding: 10px 30px;
    }

    .swal2-content {
      white-space: pre-wrap;
      padding: 0 30px;
      margin: 0;
      font-size: 14px;
    }

    .swal2-title {
      justify-content: center;
      margin-top: 15px;
      width: 100%;
      color: colors.$blue-gray-900 !important;
      line-height: 26px;
      margin: 0 !important;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }

    .swal2-html-container {
      margin: 0 auto;
      position: relative;
      color: colors.$blue-gray-900;
      text-align: center;

      .highlight {
        color: colors.$teal-600;
      }
    }

    .swal2-footer {
      margin: 0;
      padding: 0 25px;
      padding-top: 24px;
      font-size: 14px;
      border-top: none;
    }

    .swal2-actions {
      display: flex;
      gap: 15px;
      flex-direction: column;
      margin: 0;
      padding: 24px;
      padding-bottom: 0;

      button {
        @include typography.p();
        text-align: center !important;
        padding: 14px;
        text-transform: uppercase;
        width: 100%;
        margin: 0;
      }

      .confirm-button {
        background: colors.$teal-600;
        border-radius: 4px;
      }

      .cancel-button {
        background: colors.$white !important;
        color: colors.$blue-gray-700;
      }
    }

    &.troubleshoot-logout,
    &.troubleshoot-clear-cache {
      .swal2-content {
        color: colors.$blue-gray-700;
        font-size: 16px;
        line-height: 24px;
      }

      .swal2-actions {
        button {
          text-transform: none;
        }

        .confirm-button {
          background: colors.$rose-800;
          padding: 14px;
          font-size: 16px;
          line-height: 24px;
        }

        .cancel-button {
          @include cta.link-cta;
          border-radius: 4px;
          color: colors.$blue-gray-700;
          border: 1px solid colors.$blue-gray-500;
          padding: 12px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .swal2-modal.has-icon {
    width: 573px !important;

    .swal2-error.swal2-icon-show {
      border: none;
      background: url('../images/icon-error.svg') no-repeat center;
      height: 64px;
      width: 64px;
      border-radius: unset;

      .swal2-x-mark {
        display: none;
      }
    }

    .swal2-success.swal2-icon-show {
      border: none;
      background: url('../images/icon-success.gif') no-repeat center;
      background-size: 64px;
      height: 64px;
      width: 64px;
      border-radius: unset;

      > * {
        display: none;
      }
    }
  }

  .swal2-icon-warning.swal2-show {
    width: 357px !important;

    .swal2-header {
      padding: 32px 24px 16px 24px;
    }

    .swal2-warning.swal2-icon-show {
      border: none;
      margin: 0;
      background: url('../images/icon-warning.svg') no-repeat center;
      height: 64px;
      width: 64px;

      .swal2-icon-content {
        display: none;
        padding-top: 16px;
      }
    }

    .swal2-actions {
      padding-bottom: 32px !important;
    }
  }
}
