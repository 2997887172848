@use "../common/scrollbar";

.kds-ticket-list-widget {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-auto-flow: column;
  gap: 20px;
  padding: 0 24px 24px;
  overflow: scroll;
  @include scrollbar.hide;
}
