@use "typography";
@use "constants";
@use "colors";

$horizontal-gap: 18px;

@mixin container {
  background-color: colors.$white;
  border-radius: constants.$border-radius;
  border: 1px solid #f0f0f0;
}

@mixin header {
  padding: 12px $horizontal-gap;
}

@mixin header-font {
  @include typography.h3;
}

@mixin content {
  padding: 15px $horizontal-gap;
}

@mixin footer {
  border-top: 1px solid #f1f1f5;
  padding: 15px $horizontal-gap;
  background-color: #fafafb;
  border-bottom-left-radius: constants.$border-radius;
  border-bottom-right-radius: constants.$border-radius;
}

/**
 * Stack 
 */
@mixin stack {
  display: grid;
  grid-template-rows: 1fr min-content;
  background-color: #fafafb;
}

@mixin upper {
  border-bottom: 3px solid #f1f1f5;
  border-radius: constants.$border-radius;
  background-color: white;
}

@mixin lower {
  padding: 5px 12px;
}

@mixin round-buttons {
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: left;
}

@mixin round-button {
  font-size: 30px;
  color: #999;
  cursor: pointer;

  img {
    width: 26px;
    margin-right: 5px;
  }
}
