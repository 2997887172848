@use "../common/colors";
@use "../common/typography";

.kds-ticket-list-item-body-widget {
  padding: 8px 16px;
  overflow: scroll;

  .item {
    display: grid;
    grid-template-areas:
      'quantity name'
      '. item-description'
      '. item-comments';
    margin-bottom: 12px;
    justify-content: start;
    gap: 2px 4px;

    .item-quantity {
      grid-area: quantity;
      padding-right: 4px;
      @include typography.button-2;
    }

    .item-name {
      grid-area: name;
      color: colors.$blue-gray-900;
      @include typography.body-3-500;
      line-height: 21px;
    }

    .item-description {
      grid-area: item-description;
      color: colors.$blue-gray-500;
      @include typography.caption-2-400;

      > div {
        white-space: break-spaces;
      }

      > div:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }

    .item-comments {
      grid-area: item-comments;
      color: colors.$blue-gray-500;
    }
  }

  .comments {
    @include typography.caption-2-400;
    color: colors.$blue-gray-500;
  }

  &.cancelled,
  &.rejected {
    .item *,
    .comments {
      color: colors.$blue-gray-300;
    }
  }

  &.completion-in-progress {
    opacity: 25%;
    text-decoration: line-through;
  }
}
