@use "../common/colors";
@use "../common/typography";
@use "../common/kds-ticket-list-item-widget";

.completed-kds-ticket-list-widget {
  > .completed-kds-ticket-list-item-widget {
    @include kds-ticket-list-item-widget.container();
    overflow: hidden;

    > .kds-ticket-list-item-footer-widget {
      @include typography.caption-1-400;
      border-top: 1px solid colors.$blue-gray-100;
      padding: 16.5px 16px;
      background-color: colors.$blue-gray-50;
    }
  }
}
