@use "colors";
@use "elements";

/**
  * Drop Down
  */
@mixin drop-down {
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 16px;
    background-color: colors.$white;
    border: solid 1px colors.$blue-gray-100;
    border-radius: 8px;

    .option {
      display: flex;
      justify-content: space-between;
      padding: 10px 12px;
      border-bottom: 1px solid colors.$blue-gray-100;

      /* Remove bottom margin if last option */
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/* Form Drop Down Input */
@mixin form-drop-down-input {
  display: flex;
  position: relative;
  z-index: 999;
  cursor: pointer;

  .value {
    flex-grow: 1;
    padding: 0px 12px;
  }

  .down-arrow-icon {
    @include elements.icon($size: 24px);
    margin: -10px 12px 0px 0px;
  }

  @include drop-down;
}
